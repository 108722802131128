// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { IconButton } from 'components/Buttons';
import { Button } from 'components/Buttons/Button';
import { CustomIcon } from 'components/CustomIcon';
import MBTextField from 'components/Forms/MBTextField/MBTextField';
import TypographyComponent from 'components/Typography/Typography';
import {
  TopRightAngle,
  TopLeftAngle,
  BottomLeftAngle,
  BottomRightAngle,
} from 'icons';
import { theme } from 'theme';
import pxToEm from 'theme/pxToEm';

const MAX_HEIGHT = '900px';
const TOP_PADDING = '27px';

export const FullScreenButtonContainer = styled.div`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  margin-top: ${pxToEm(12)};
  margin-bottom: ${pxToEm(32)};
  & span {
    margin-right: ${pxToEm(12)};
  }
`;

export const MoreInfoWrapper = styled.div`
  padding-bottom: ${pxToEm(12)};
  & button {
    margin-left: ${pxToEm(-10)};
    background: none !important;
    box-shadow: none !important;
  }
`;

export const CloseBookModalWrapper = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: ${pxToEm(20)};
`;

export const Wrapper = styled.div<{
  fullHeight?: boolean;
  reduceHeight: number;
  top: number;
  isImagePdp?: boolean;
}>`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ fullHeight, reduceHeight, isImagePdp }) =>
    isImagePdp
      ? 'auto'
      : fullHeight
      ? '100vh'
      : `calc(${MAX_HEIGHT} - ${reduceHeight}px)`};
  justify-content: ${({ fullHeight }) => (fullHeight ? 'stretch' : 'center')};
  padding: 0;
  z-index: ${({ fullHeight }) => (fullHeight ? 10 : 'auto')};

  .rightGrid {
    ${theme.breakpoints.up('md')} {
      min-height: ${({ fullHeight, reduceHeight, isImagePdp }) =>
        isImagePdp
          ? 'auto'
          : fullHeight
          ? '100vh'
          : `calc(${MAX_HEIGHT} - ${reduceHeight}px - ${TOP_PADDING})`};
    }
  }
  .MuiDialog-paper {
    background: white !important;
    min-height: 100% !important;
    justify-content: center;
    padding-top: 0;
  }
`;

export const RightColumn = styled.div<{ withBorder: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  ${theme.breakpoints.up('md')} {
    ${({ withBorder }) =>
      withBorder ? `border-left: 1px solid ${theme.palette.grey[50]};` : ''};
    height: 100%;
    padding-left: 1.5em;
  }
  hr {
    border-color: ${theme.palette.grey[50]};
  }
`;

export const BreakedText = styled(TypographyComponent)`
  ${theme.breakpoints.down('md')} {
    margin-bottom: ${pxToEm(8)};
    font-size: 24px;
    line-height: 32px;
  }
  overflow-wrap: break-word;
  line-height: 1;
`;

export const HotspotBox = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  background: ${theme.palette.secondary.main};
  padding: 12px;
  border-radius: 48px;
  cursor: pointer;
  ${theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const HotspotText = styled(TypographyComponent)`
  padding-left: 0;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

export const ImageContainer = styled.div`
  margin-top: ${pxToEm(16)};
  width: 100%;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 3em;
  ${theme.breakpoints.down('md')} {
    margin-bottom: ${pxToEm(12)};
  }
`;

export const ImageWrapper = styled.div<{ isPrimary?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  ${({ isPrimary }) => isPrimary && 'order: -1;'}
  ${theme.breakpoints.up('md')} {
    &:hover {
      ${HotspotText} {
        max-width: 300px;
        padding-left: 8px;
      }
    }
  }
  &:hover {
    & .likeButton {
      display: flex;
    }
  }
  .rangeSlider {
    color: #fff;
  }
  .resizeCornerHandler {
    width: 48px !important;
    height: 48px !important;
    z-index: 2;
    max-width: 30% !important;
    max-height: 30% !important;
  }
`;

export const ScrollContainer = styled.div`
  column-gap: ${pxToEm(8)};
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  row-gap: ${pxToEm(8)};
  width: 100vw;
  margin: 0 -1.25rem;
  margin-bottom: ${pxToEm(10)};
  margin-top: ${pxToEm(5)};
  padding: 0 1.25rem;
  ${theme.breakpoints.up('md')} {
    flex-wrap: wrap;
    width: unset;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ScrollItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${pxToEm(3)} 0;
  flex: 0 0 auto;
`;

export const AuthorsCredentialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0; /* or some value */
`;

export const TruncatedText = styled(TypographyComponent)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MainImage = styled.img<{ isHotspot?: boolean }>`
  border-radius: ${pxToEm(32)};
  pointer-events: none;
  max-height: 250vh;
  width: 100%;
  min-width: 250px;
  min-height: 300px;
  filter: ${({ isHotspot }) => (isHotspot ? 'brightness(0.5)' : 'none')};
`;

export const BookOpacityWrapper = styled.div<{
  shouldShow: boolean;
  isBookModalOpen: boolean;
}>`
    width: 100%;
    height: 100%;
    opacity: ${({ shouldShow }) => (shouldShow ? 1 : 0)};
    visible: ${({ shouldShow }) => (shouldShow ? 'inherit' : 'hidden')};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    ${({ isBookModalOpen }) => (isBookModalOpen ? 'padding-top: 27px;' : '')})}
`;

export const BookModalAbsoluteWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CreditWrapper = styled.div`
  padding-top: ${pxToEm(25)};
  ${theme.breakpoints.up('md')} {
    margin-top: auto;
    padding: ${pxToEm(12)} 0 0 0;
    text-align: right;
  }
`;

export const SaveButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isBuyButton',
})`
  width: ${pxToEm(84)};
  margin-top: ${pxToEm(20)};
  margin-bottom: ${pxToEm(26)};
  margin-right: ${({ isBuyButton }) => (isBuyButton ? '12px' : 0)};
  ${theme.breakpoints.down('md')} {
    margin-top: ${pxToEm(8)};
    margin-bottom: ${pxToEm(20)};
  }
`;

export const DescriptionWrapper = styled.div`
  ${theme.breakpoints.down('md')} {
    margin-top: ${pxToEm(14)};
    margin-bottom: 8px;
    gap: ${pxToEm(12)};
  }
  margin-block: ${pxToEm(24)};
  display: flex;
  flex-direction: column;
  gap: ${pxToEm(16)};
`;

export const AuthorInfoLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToEm(4)};
`;

export const GridWrapper = styled(Grid)`
  ${theme.breakpoints.down('md')} {
    flex-direction: 'column';
  }
`;

export const LikeButton = styled(IconButton, {
  shouldForwardProp: (prop) => !['isFavorite'].includes(prop),
})<{ isFavorite: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: none;
  margin: ${pxToEm(20)};
  ${({ theme, isFavorite }) =>
    isFavorite
      ? `
        svg {
          fill: ${theme.palette.grey[900]};
        }
      `
      : ''}
  ${(props) => props.theme.breakpoints.down('md')} {
    display: flex;
    background: transparent;
  }
  ${theme.breakpoints.down('md')} {
    display: flex;
    background: transparent;
  }
`;

export const ImagePreview = styled.img`
  margin-bottom: ${pxToEm(12)};
  border-radius: ${pxToEm(20)};
  width: 100%;
  height: auto;
`;

export const ImagePreviewWrapper = styled.div`
  position: relative;
  display: flex;
  &:hover {
    & .likeButton {
      display: flex;
    }
  }
`;

export const GetBackArrowWrapper = styled.div`
  cursor: pointer;
  margin-top: ${pxToEm(12)};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const GetBackBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 22px;
`;

export const GetBackArrow = styled(CustomIcon)`
  margin-bottom: ${pxToEm(12)};
  margin-right: ${pxToEm(8)};
  transform: rotate(180deg);
`;

export const LeaveFeedback = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
`;

export const SingleImageGrid = styled(Grid)`
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 100%;
    position: relative;
    top: 0;
  }
`;

export const MaterialsGridWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 1em;
`;

export const MaterialCell = styled.div`
  width: 33.33%;
  padding: 8px;
`;

export const GridCell = styled.div`
  width: 20%;
  padding: 8px;
`;

export const MaterialsHeader = styled(TypographyComponent)`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColorsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
`;

export const ColorOuterWrapper = styled.div`
  width: 100%;
  position: relative;
  div {
    align-items: flex-start;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    height: 20px;
    right: 0;
    width: 1px;
    background: #f2f2f2;
  }
`;

export const ColorCirlce = styled.div<{ color: string; isWhite?: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: ${({ color }) => color};
  margin-right: 12px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center !important;
  justify-content: center;
  color: #fff;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  svg {
    width: 75% !important;
    height: 75% !important;
    ${({ isWhite, theme }) => isWhite && `color: ${theme.palette.grey[900]}`}
  }
  &:hover {
    opacity: 0.8;
  }
  box-shadow: 0 0 1px 0 #000;
`;

interface Position {
  x: number;
  y: number;
}
interface HotspotMiniImageProps {
  width: number;
  height: number;
  imageUrl: string;
  position: Position;
}

const borderWidth = 4;

export const HotspotMiniImage = styled.div<HotspotMiniImageProps>`
  display: flex;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  top: 4px;
  left: 4px;
  background-size: ${({ width, height }) => `${width}px ${height}px`};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  bakground-position: top left;
  background-position: ${({ position }) =>
    `-${position.x + borderWidth}px -${position.y + borderWidth}px`};
  border-radius: 32px;
`;

export const TopLeftCorner = styled(TopLeftAngle)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const TopRightCorner = styled(TopRightAngle)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

export const BottomRightCorner = styled(BottomRightAngle)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
`;

export const BottomLeftCorner = styled(BottomLeftAngle)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

export const LineDivider = styled(Divider)`
  margin-bottom: 1em;
  width: 100%;
`;

export const HotspotResultsTopbar = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const SearchInput = styled(MBTextField)`
  .MuiInputBase-adornedStart {
    height: 36px;
    svg {
      margin-right: 6px;
    }
  }
  .MuiInputBase-input {
    padding: 0 !important;
  }
  svg {
    cursor: pointer;
  }
  *:after {
    display: none !important;
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  form {
    width: 100%;
  }
`;

export const CancelButton = styled(Button)`
  margin-left: 8px;
  &:hover {
    background: none;
  }
`;

export const TriggerSearchButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  font-size: 0.65em;
  &:hover {
    color: #4d4d4d;
    background-color: #f5f5f7;
  }
`;

export const ScrollAppendix = styled.div`
  width: 4px;
  height: 36px;
  pointer-events: none;
  flex-shrink: 0;
`;

export const NoResultsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  min-height: 35vh;
`;
