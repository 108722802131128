import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Provider } from 'react-redux';
import { ErrorModal } from './components/ErrorModal';
import { Snackbar } from './components/Snackbar';
import { HotspotMainWrapper } from './HotspotMainWrapper';
import store from './redux/store';
import * as Styled from './VisualSearchModal.styled';
import { definedTranslation } from '../SearchBar/i18n';

const VisualSearchModal = () => {
  const [currentMaterial, setCurrentMaterial] = useState(undefined);
  const scrollerRef = useRef(null);
  const isModalOpen = Boolean(currentMaterial);
  const intl = useIntl();

  const labels = {
    title: intl.formatMessage(
      definedTranslation.visualSearch.resultsModal.title,
    ),
  };

  useEffect(() => {
    window.addEventListener('initVisualSearch', handleInitVisualSearch);
    return () => {
      window.removeEventListener('initVisualSearch', handleInitVisualSearch);
    };
  }, []);

  const handleInitVisualSearch = (e: CustomEvent) => {
    if (e.detail?.item) {
      setCurrentMaterial(e.detail.item);
    }
  };

  const handleClose = () => {
    setCurrentMaterial(undefined);
    console.log('HERE!!!11');
    // temp fix for page scroll on modal close
    setTimeout(() => {
      document?.getElementById('html-body')?.removeAttribute('style');
    }, 400);
  };

  if (!isModalOpen) {
    return <Fragment />;
  }

  return (
    // @ts-expect-error // TODO: fix types
    <Provider store={store}>
      <Styled.ModalWrapper ref={scrollerRef}>
        <Styled.Dialog
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          disableRestoreFocus
          title={labels.title}
          id="VisualSearchModalWindow"
          showCloseIcon
          open={isModalOpen}
          onClose={handleClose}
          dialogWidth={1400}
        >
          <HotspotMainWrapper currentItem={currentMaterial} />
        </Styled.Dialog>
        <Snackbar />
        <ErrorModal dialogWidth={480} />
      </Styled.ModalWrapper>
    </Provider>
  );
};

export default VisualSearchModal;
