import { endpoints } from '../../config';
import { fetchWithTimeout } from '../../helpers';
import { xRequestIdSelector } from '../../redux/selectors/hotspotSelector';
import store from '../../redux/store';

interface RequestProps {
  id: string | string[];
  type: 'view' | 'cart' | 'share' | 'favorite' | 'rate';
}

export const sendAnalytics = async (
  data: RequestProps,
  timeout: number = 3000,
) => {
  try {
    const xRequestId = xRequestIdSelector(store.getState());
    const response = fetchWithTimeout(endpoints.analytics, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout,
      body: JSON.stringify({
        requestId: xRequestId,
        ...data,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log('sendAnalytics', e);
      });
    return response;
  } catch (e) {
    console.log('Error sendAnalytics:', e);
  }
};
