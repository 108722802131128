import {
  getAnalyticsItemType,
  AnalyticsEventName,
  AddToCartMethod,
} from '@mb/lib';
import { ProductSearchMaterial } from '../types/material';

// @ts-expect-error // TODO: fix types
export const pushGtmDataLayer = (payload) => {
  try {
    mitGTM.sendEvent(payload);
  } catch (e) {
    console.log('pushGtmDataLayer error', e);
  }
};

// @ts-expect-error // TODO: fix types
export function getUrlParameter(name, customUrl = null) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(customUrl || window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const getAnalyticsItemCategoriesByTaxonomy = (
  taxonomy: string[] = [],
) => {
  const itemCategory: string[] = [];
  const itemCategory2: string[] = [];
  const itemCategory3: string[] = [];

  taxonomy.forEach((value) => {
    const splittedTaxonomy = value.split('>') ?? [];
    if (splittedTaxonomy[0] && !itemCategory.includes(splittedTaxonomy[0])) {
      itemCategory.push(splittedTaxonomy[0]);
    }
    if (splittedTaxonomy[1] && !itemCategory2.includes(splittedTaxonomy[1])) {
      itemCategory2.push(splittedTaxonomy[1]);
    }
    if (splittedTaxonomy[2] && !itemCategory3.includes(splittedTaxonomy[2])) {
      itemCategory3.push(splittedTaxonomy[2]);
    }
  });
  return {
    item_category: itemCategory.join(', '),
    item_category2: itemCategory2.join(', '),
    item_category3: itemCategory3.join(', '),
  };
};

export const mapMaterialsForAnalytics = (
  data: ProductSearchMaterial[] = [],
  // @ts-expect-error // TODO: fix types
  currentColor,
  // @ts-expect-error // TODO: fix types
  itemListId,
  // @ts-expect-error // TODO: fix types
  itemListName,
) => {
  const configLang =
    typeof ENVIRONMENT_REGION !== 'undefined' ? ENVIRONMENT_REGION : null;
  const currency = configLang === 'EU' ? 'EUR' : 'USD';

  const gaType = data?.map((item, index) => {
    const colorway = item.ssFacetColorFilter?.find(
      (color) => color.image_url === item.thumbnailImageUrl?.split('?')?.[0],
    );
    let itemColor = null;
    if (colorway != null) {
      itemColor = colorway?.color_name;
    }
    const finishesColor = item?.configData?.color;

    let itemId = item.sku;
    try {
      itemId = item?.configurations[0]?.items[0]?.sku;
    } catch (e) {
      console.log(e.message);
    }

    const finalObj = {
      item_id: itemId,
      item_name: item.name,
      item_brand: item.manufacturer,
      item_type: getAnalyticsItemType(item),
      ...getAnalyticsItemCategoriesByTaxonomy(item.taxonomy ?? []),
      index: item.gridIndex || index + 1,
      price: 0,
      quantity: 1,
      item_list_id: itemListId,
      item_list_name: itemListName,
      item_variant: finishesColor || currentColor || itemColor || '',
    };

    if (item.associatedItem != null) {
      // @ts-expect-error // TODO: fix types
      finalObj['associated_item'] = item.associatedItem || '';
    }

    return finalObj;
  });

  return { gaType, currency };
};

export const pushSearchAnalyticsDataLayer = (
  payload: ProductSearchMaterial[] = [],
  currentColor = '',
  listId = 'visual_search',
  listTitle = 'Visual Search',
  eventName = 'view_item_list',
  // @ts-expect-error // TODO: fix types
  eventLocation,
  isFromCatalog = false,
  isSearch = false,
) => {
  try {
    const currentQuery = getUrlParameter('q');
    let finalListId = listId;
    let finalListTitle = listTitle;

    if (isSearch || currentQuery?.length > 0) {
      finalListId = 'search_results_sampling';
      finalListTitle = 'Sampling search results';
    }

    const { gaType, currency } = mapMaterialsForAnalytics(
      payload,
      currentColor,
      finalListId,
      finalListTitle,
    );

    const gaEvent = {
      event: eventName,
      list_id: finalListId,
      list_name: finalListTitle,
      ecommerce: {
        currency,
        value: 0,
        items: gaType,
      },
    };

    if (eventName === AnalyticsEventName.ADD_TO_CART) {
      // @ts-expect-error // TODO: fix types
      gaEvent['method'] =
        gaType.length > 1
          ? AddToCartMethod.MULTI_SELECT
          : AddToCartMethod.SINGLE;
    }

    if (eventLocation != null) {
      // @ts-expect-error // TODO: fix types
      gaEvent['event_location'] = eventLocation;
    }
    if (listId != null && isFromCatalog) {
      // @ts-expect-error // TODO: fix types
      gaEvent.ecommerce['item_list_id'] = finalListId;
    }
    if (listTitle != null && isFromCatalog) {
      // @ts-expect-error // TODO: fix types
      gaEvent.ecommerce['item_list_name'] = finalListTitle;
    }

    mitGTM.sendEvent({ ecommerce: null });
    mitGTM.sendEvent(gaEvent);
    console.log(gaEvent);
  } catch (e) {
    console.log(e);
  }
};

/**
 * Build URL params for Analytics tracking on PDP based on the search list
 * @param params
 * @return string
 */
export const buildAnalyticsParamsToPDP = (params: {
  product: any;
  itemListId?: string;
  itemListName?: string;
  index?: number;
}) => {
  const { product, itemListId, itemListName, index } = params;

  const urlParams = [];
  itemListId && urlParams.push(`itemListId=${itemListId}`);
  itemListName && urlParams.push(`itemListName=${itemListName}`);
  urlParams.push(`index=${product?.gridIndex ?? product?.index ?? index}`);

  return urlParams.join('&');
};

export const mapProductToAnalyticsEcommerceItem = (params: {
  product: ProductSearchMaterial;
  itemListId?: string;
  itemListName?: string;
  index?: number;
}) => {
  const { product, itemListId, itemListName, index } = params;

  const colorway = product.ssFacetColorFilter?.find(
    (color) => color.image_url === product.thumbnailImageUrl?.split('?')?.[0],
  );
  const itemColor = colorway != null ? colorway?.color_name : null;
  const finishesColor = product?.configData?.color;

  return {
    item_id: product.sku,
    item_name: product.name,
    item_brand: product.manufacturer,
    ...getAnalyticsItemCategoriesByTaxonomy(product.taxonomy),
    index: product.gridIndex ?? product.index ?? index,
    price: 0,
    quantity: 1,
    item_list_id: itemListId,
    item_list_name: itemListName,
    item_variant: finishesColor ?? itemColor ?? '',
  };
};
