import axios from 'axios';

// @ts-expect-error // TODO: fix types
export const checkMultipleMaterialsAvailability = async (materialsIds) => {
  try {
    if (materialsIds?.length === 0) {
      return null;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('items', materialsIds);
    bodyFormData.append('origin', 'search');

    const response = await axios.post(
      '/catalog/product/inventory',
      bodyFormData,
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    console.error('Error while checking stock >>> ', error.message);

    return null;
  }
};
