import { ColorGroup, ContentTextile } from '../HotspotMainWrapper/types';

export interface Position {
  x: number;
  y: number;
}
export interface Size {
  width: number;
  height: number;
}
export interface Color {
  Name: string;
  hex_code: string;
  density: number;
  isSelected?: boolean;
}
export interface Label {
  label: string;
  score: number;
}
export interface ImageSize {
  width: number;
  height: number;
}
export interface CropData {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}
export interface HotspotState {
  crop: CropData;
  query?: string;
  colors?: ColorGroup[];
  labels?: ContentTextile[];
  selectedColors?: string[];
  selectedLabels?: string[];
  isSearching: boolean;
  isSearchComplete: boolean;
  results: any[];
  xRequestId?: string;
}

export enum ResponseStatusEnum {
  FAILED = 'failed',
  SUCCESS = 'success',
}

export enum SnackbarStatusEnum {
  DANGER = 'danger',
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum FilterLabels {
  COLOR = 'virtual_color_group',
  TAXONOMY = 'content_textile',
}
