import { isEuEnv, getUserLang } from '@mb/lib/i18n';

const configGlobal = typeof SEARCH_CONFIG !== 'undefined' ? SEARCH_CONFIG : {};
const apiURL =
  configGlobal?.product?.siteUrl?.replace(/\/$/, '') ||
  'https://search.materialbank.com/v2';

const apiURLv1 = '/rest/V1';
const apiURLv2 = '/rest/V2/sdg-api';

const userLang = getUserLang();
const langPrefix = isEuEnv ? `/${userLang}` : '';

export const endpoints = {
  getProjectTypes: `${apiURLv1}/sdg-api/projects/type/list`,
  projects: `${apiURLv2}/projects/mine/`,
  getServices: '/mit_diverzify/diverzify',
  getConfigurableProduct: `${apiURLv1}/sdg-api/getproduct2`,
  addToBag: `${langPrefix}/sdg_cart/lineitem/addmultiple`,
  analytics: `${apiURL}/analytics/event`,
  postCurrentUserProject: '/sdg_project/project/projectssetlist',
};
