import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { HotspotState } from '../../types';

const defaultState: HotspotState = {
  colors: [],
  results: [],
  selectedColors: [],
  labels: [],
  selectedLabels: [],
  query: '',
  isSearching: false,
  isSearchComplete: false,
  xRequestId: undefined,
  crop: {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  },
};
export const initialState: HotspotState = cloneDeep(defaultState);
const hotspotSlice = createSlice({
  name: 'hotspot',
  initialState,
  reducers: {
    setXRequestId(state, { payload }) {
      state.xRequestId = payload;
    },
    setColors(state, { payload }) {
      state.colors = payload;
    },
    setResults(state, { payload }) {
      state.results = payload;
    },
    setSelectedColors(state, { payload }) {
      state.selectedColors = payload;
    },
    setQuery(state, { payload }) {
      state.query = payload;
    },
    setCropPositions(state, { payload }) {
      state.crop = payload;
    },
    setIsSearching(state, { payload }) {
      state.isSearching = payload;
    },
    setLabels(state, { payload }) {
      state.labels = payload;
    },
    setSelectedLabels(state, { payload }) {
      state.selectedLabels = payload;
    },
    setIsSearchComplete(state, { payload }) {
      state.isSearchComplete = payload;
    },
    resetFilters(state) {
      state.selectedLabels = initialState.selectedLabels;
      state.selectedColors = initialState.selectedColors;
    },
    resetHotspotData(state) {
      state.colors = initialState.colors;
      state.labels = initialState.labels;
      state.selectedLabels = initialState.selectedLabels;
      state.selectedColors = initialState.selectedColors;
      state.crop = initialState.crop;
      state.query = initialState.query;
      state.isSearching = initialState.isSearching;
      state.isSearchComplete = initialState.isSearchComplete;
    },
  },
});
export const {
  setColors,
  setSelectedColors,
  setQuery,
  setCropPositions,
  resetHotspotData,
  setIsSearching,
  setIsSearchComplete,
  setResults,
  setLabels,
  setSelectedLabels,
  setXRequestId,
  resetFilters,
} = hotspotSlice.actions;
export default hotspotSlice.reducer;
