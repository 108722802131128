import React, { Fragment, useState } from 'react';
import { Rnd } from 'react-rnd';
import * as Styled from './HotspotMainWrapper.styled';
import { Size, Position } from './types';

interface Props {
  imageUrl: string;
  containerRef: React.RefObject<HTMLInputElement | null>;
  onSpotChange: (size: Size, pos: Position) => void;
}

const DraggableAreaWrapper: React.FC<Props> = ({
  imageUrl,
  containerRef,
  onSpotChange,
}) => {
  const [size, setSize] = useState<Size>({ width: 240, height: 240 });
  const [position, setPosition] = useState<Position>({ x: 32, y: 32 });
  const [isResizing, setIsResizing] = useState(false);

  return (
    <Rnd
      bounds="parent"
      minWidth={80}
      minHeight={80}
      maxWidth="100%"
      maxHeight="100%"
      className="rangeSlider"
      size={{
        width: size?.width,
        height: size?.height,
      }}
      resizeHandleClasses={{
        bottomLeft: 'resizeCornerHandler',
        bottomRight: 'resizeCornerHandler',
        topLeft: 'resizeCornerHandler',
        topRight: 'resizeCornerHandler',
      }}
      dragAxis="both"
      position={{
        x: position.x,
        y: position.y,
      }}
      onDrag={(e: any, delta: any) => {
        if (isResizing) {
          return;
        }
        setPosition({ x: delta.x, y: delta.y });
      }}
      onDragStop={(e: any, delta: any) => {
        if (isResizing) {
          return;
        }
        const newPos = { x: delta.x, y: delta.y };
        setPosition({ x: delta.x, y: delta.y });
        onSpotChange?.(size, newPos);
      }}
      onResize={(e, direction, ref, delta, position) => {
        setIsResizing(true);
        setPosition(position);
        setSize({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setIsResizing(false);
        onSpotChange?.(
          {
            width: ref.offsetWidth,
            height: ref.offsetHeight,
          },
          position,
        );
      }}
    >
      <Fragment>
        <Styled.TopLeftCorner />
        <Styled.TopRightCorner />
        <Styled.HotspotMiniImage
          imageUrl={imageUrl}
          width={containerRef?.current?.offsetWidth || 0}
          height={containerRef?.current?.offsetHeight || 0}
          position={position}
        />
        <Styled.BottomLeftCorner />
        <Styled.BottomRightCorner />
      </Fragment>
    </Rnd>
  );
};

export default DraggableAreaWrapper;
