import React, { useEffect, useRef, Fragment } from 'react';
import Grid from '@mui/material/Grid';

import ColorCirclesWrapper from './ColorCirclesWrapper';
import DraggableAreaWrapper from './DraggableAreaWrapper';
import HeaderWrapper from './HeaderWrapper';
import * as Styled from './HotspotMainWrapper.styled';
import HotspotMaterials from './HotspotMaterials';
import {
  HotspotMaterialsSkeleton,
  MatchesLoadingSkeleton,
} from './HotspotMaterialsSkeleton';
import LabelsWrapper from './LabelsWrapper';
import { Size, Position, ImageSize, CropData } from './types';
import { getMaterialsByHotspot } from '../api/hotspot';
import { getUserProjectTypes, getUserProjects } from '../api/projects';
import { isSearchingSelector } from '../redux/selectors/hotspotSelector';
import {
  resetFilters,
  resetHotspotData,
  setCropPositions,
} from '../redux/sliceCreators/hotspotSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';

import { UploadedImage } from '../types/material';

interface Props {
  currentItem?: UploadedImage;
}

const HotspotMainWrapper: React.FC<Props> = ({ currentItem }) => {
  const imgWrapperRef = useRef<HTMLInputElement | null>(null);
  const isHotspotSearching = useAppSelector(isSearchingSelector);
  const dispatch = useAppDispatch();

  const currentImage = currentItem?.image_shared_url;

  useEffect(() => {
    dispatch(getUserProjects());
    dispatch(getUserProjectTypes());
    return () => {
      dispatch(resetHotspotData());
    };
  }, []);

  const handleSpotChange = async (newSize: Size, newPosition: Position) => {
    if (imgWrapperRef?.current == null) {
      return;
    }
    dispatch(resetFilters());
    const hotspotPosition = calcHostpotPosition(newSize, newPosition);
    //await getNewColors(hotspotPosition);
    getResults(hotspotPosition, true, false);
  };

  const calcHostpotPosition = (
    size: Size,
    position: Position,
    imgSize?: ImageSize,
  ): CropData => {
    if (imgWrapperRef?.current == null) {
      return { x1: 0, x2: 0, y1: 0, y2: 0 };
    }

    const imageWidth = imgSize?.width || imgWrapperRef?.current?.offsetWidth;
    const imageHeight = imgSize?.height || imgWrapperRef?.current?.offsetHeight;

    return {
      x1: Math.min(position.x / imageWidth, 1),
      x2: Math.min((position.x + size.width) / imageWidth, 1),
      y1: Math.min(position.y / imageHeight, 1),
      y2: Math.min((position.y + size.height) / imageHeight, 1),
    };
  };

  const getResults = async (
    body: CropData,
    isNewSpot?: boolean,
    isInit?: boolean,
  ) => {
    dispatch(setCropPositions(body));
    dispatch(
      getMaterialsByHotspot({
        crop: body,
        imageUrl: currentImage,
        isNewSpot,
        isInit,
      }),
    );
  };

  const handleImageLoad = async (e: React.SyntheticEvent) => {
    // @ts-expect-error // TODO: fix types
    if (e?.target?.clientWidth != null && e?.target?.clientHeight != null) {
      // refactor later, put those number in Slice
      const newDots = calcHostpotPosition(
        { width: 240, height: 240 },
        { x: 32, y: 32 },
        {
          // @ts-expect-error // TODO: fix types
          width: e?.target?.clientWidth,
          // @ts-expect-error // TODO: fix types
          height: e?.target?.clientHeight,
        },
      );
      //await getNewColors(newDots);
      getResults(newDots, true, true);
    }
  };

  if (!currentImage) {
    return <MatchesLoadingSkeleton />;
  }

  return (
    <Styled.Wrapper isImagePdp fullHeight={false} reduceHeight={0} top={0}>
      <Styled.GridWrapper container spacing={0}>
        <Styled.SingleImageGrid item xs={12} md={6} lg={6}>
          <Styled.ImageContainer>
            <Styled.ImageWrapper ref={imgWrapperRef}>
              <Styled.MainImage
                isHotspot
                src={currentImage}
                alt="Matches"
                onLoad={handleImageLoad}
                data-pin-nopin
              />
              <DraggableAreaWrapper
                imageUrl={currentImage}
                containerRef={imgWrapperRef}
                onSpotChange={handleSpotChange}
              />
            </Styled.ImageWrapper>
          </Styled.ImageContainer>
        </Styled.SingleImageGrid>
        <Grid className="rightGrid" item xs={12} md={6} lg={6}>
          <Styled.RightColumn withBorder>
            <HeaderWrapper />
            {isHotspotSearching ? (
              <HotspotMaterialsSkeleton />
            ) : (
              <Fragment>
                <LabelsWrapper image={currentImage} />
                <ColorCirclesWrapper image={currentImage} />
                <HotspotMaterials />
              </Fragment>
            )}
          </Styled.RightColumn>
        </Grid>
      </Styled.GridWrapper>
    </Styled.Wrapper>
  );
};

export default HotspotMainWrapper;
