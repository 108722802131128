import React, { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import HorizontalScroll from 'components/Elements/HorizontalScroll/HorizontalScroll';
import MBChip from 'components/Elements/MBChip/MBChip';
import { Check as CheckIcon } from 'icons';
import xor from 'lodash/xor';
import { theme } from 'theme';
import * as Styled from './HotspotMainWrapper.styled';
import { ColorGroup } from './types';
import { getMaterialsByHotspot } from '../api/hotspot';
import {
  hotspotColorsSelector,
  hotspotQuerySelector,
  hotspotSelectedColorsSelector,
} from '../redux/selectors/hotspotSelector';
import {
  setSelectedColors,
  setQuery,
} from '../redux/sliceCreators/hotspotSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: '2px 8px',
    borderRadius: '20px',
    position: 'relative',
    top: '-10px',
  },
}));

const ColorCirclesWrapper: React.FC<{ image: string }> = ({ image }) => {
  const [text, setText] = useState('');
  const [isSearchShown, setIsSearchShown] = useState(false);
  const horizontalRef = React.useRef<{ resetScroll: () => void }>(null);
  const colors = useAppSelector(hotspotColorsSelector);
  const selectedColors = useAppSelector(hotspotSelectedColorsSelector);
  const hotspotQuery = useAppSelector(hotspotQuerySelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    horizontalRef.current?.resetScroll();
  }, [colors]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };
  const onCancelTextClick = () => {
    setText('');
    setIsSearchShown(false);
  };
  const onClearTextClick = () => {
    setText('');
  };
  const showSearch = () => {
    setIsSearchShown(true);
  };

  const handleColorClick = (color: ColorGroup) => {
    const newSelectedColors = xor(selectedColors, [color.label]);
    dispatch(setSelectedColors(newSelectedColors));
    dispatch(
      getMaterialsByHotspot({
        colors: newSelectedColors,
        imageUrl: image,
      }),
    );
  };

  const removeHotspotQuery = () => {
    dispatch(setQuery(''));
    dispatch(getMaterialsByHotspot({}));
  };

  const handleQuery = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(setQuery(text));
    dispatch(
      getMaterialsByHotspot({
        similarityText: text,
      }),
    );
    onCancelTextClick();
  };

  return (
    <Styled.HotspotResultsTopbar>
      <Styled.LineDivider />
      <Fragment>
        <Styled.ColorOuterWrapper>
          <HorizontalScroll
            isDraggable={false}
            showArrows
            arrowScrollingPercent={0.5}
            parentRef={horizontalRef}
          >
            <Styled.ColorsWrapper>
              {colors?.map((item: ColorGroup) => {
                const isSelected = item.active;
                return (
                  <BootstrapTooltip
                    key={item.hexcode}
                    title={item.label}
                    placement="bottom"
                  >
                    <Styled.ColorCirlce
                      key={item.hexcode}
                      color={item.hexcode}
                      role="button"
                      isWhite={item.hexcode?.includes('ffffff')}
                      onClick={() => handleColorClick?.(item)}
                    >
                      {isSelected && <CheckIcon />}
                    </Styled.ColorCirlce>
                  </BootstrapTooltip>
                );
              })}
              <Styled.ScrollAppendix />
            </Styled.ColorsWrapper>
          </HorizontalScroll>
        </Styled.ColorOuterWrapper>
      </Fragment>
      {(hotspotQuery || '')?.length > 0 && (
        <Fragment>
          <Styled.LineDivider />
          <Box width="100%" mb="1em">
            <MBChip
              clickable
              fontSize="16px"
              color="secondary"
              variant="filled"
              removeIcon="close"
              // @ts-expect-error // TODO: fix types
              labelText={hotspotQuery}
              onDelete={removeHotspotQuery}
            />
          </Box>
        </Fragment>
      )}
    </Styled.HotspotResultsTopbar>
  );
};

export default ColorCirclesWrapper;
