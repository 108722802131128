import styled from '@emotion/styled';
import MBDialog from 'components/Elements/MBDialog';

export const Dialog = styled(MBDialog)`
  margin-top: 90px;
  margin-bottom: 180px;
  z-index: 20 !important;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  #VisualSearchModalWindow {
    height: auto;
    position: relative;
    overflow: hidden;
  }
`;
