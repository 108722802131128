import { SnackbarOrigin } from '@mui/material';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import xorBy from 'lodash/xorBy';
import { ProductSearchMaterial } from '../../types/material';

const defaultAnchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top',
};

export enum SnackbarStatusEnum {
  DANGER = 'danger',
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface SnackbarMessagePayload extends Record<string, any> {}

export enum SnackbarMessageType {
  ALL_ITEMS_ADDED_TO_CART = 'allItemsAddedToCart',
  ALL_ITEMS_ADDED_TO_CART_EXCEPT_FINISHES = 'allItemsAddedToCartExceptFinishes',
}

export interface SnackbarInfo {
  anchorOrigin?: SnackbarOrigin;
  isOpen: boolean;
  message?: string;
  messagePayload?: SnackbarMessagePayload;
  messageType?: SnackbarMessageType;
  photo?: string;
  status?: SnackbarStatusEnum;
}

export interface CommonSliceState {
  snackbarInfo: SnackbarInfo;
  cartItems: ProductSearchMaterial[];
  materialsInQueue: ProductSearchMaterial[];
  errorMessage: ErrorMessage;
}

export interface ErrorMessage {
  body: string | null;
  showCancelButton?: boolean;
  shouldDisableBottomPadding?: boolean;
  title: string | null;
}

export const initialState: CommonSliceState = {
  cartItems: [],
  materialsInQueue: [],
  snackbarInfo: {
    anchorOrigin: defaultAnchorOrigin,
    isOpen: false,
  },
  errorMessage: {
    body: null,
    showCancelButton: true,
    shouldDisableBottomPadding: false,
    title: null,
  },
};

const commonSlice = createSlice({
  initialState,
  name: 'productSearchCommon',
  reducers: {
    setSnackbarInfo(state, { payload }: PayloadAction<SnackbarInfo>) {
      state.snackbarInfo = payload;
    },
    addCartItemsInQueue(state, { payload = [] }) {
      const uniqItems = uniqBy(
        [...state.cartItems, ...payload],
        (item) => item.entityId,
      );
      state.cartItems = uniqItems;
    },
    removeCartItemFromQueue(state, { payload = [] }) {
      state.cartItems = xorBy([...state.cartItems], payload, 'entityId');
    },
    setMaterialsInQueue(state, { payload }) {
      state.materialsInQueue = payload;
    },
    setErrorMessage(state, { payload }) {
      state.errorMessage = {
        body: payload.body || '',
        showCancelButton: payload?.showCancelButton,
        shouldDisableBottomPadding: payload?.shouldDisableBottomPadding,
        title: payload.title || '',
      };
    },
  },
});

export const {
  setSnackbarInfo,
  addCartItemsInQueue,
  removeCartItemFromQueue,
  setMaterialsInQueue,
  setErrorMessage,
} = commonSlice.actions;

export default commonSlice.reducer;
