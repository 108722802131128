import React, { Fragment, useState } from 'react';
import { SearchIcon, Cross as CrossIcon } from 'icons';
import { useIntl } from 'react-intl';
import * as Styled from './HotspotMainWrapper.styled';
import { definedTranslation } from '../../SearchBar/i18n';
import { getMaterialsByHotspot } from '../api/hotspot';
import { setQuery } from '../redux/sliceCreators/hotspotSlice';
import { useAppDispatch } from '../redux/store';

const HeaderWrapper = () => {
  const [text, setText] = useState('');
  const [isSearchShown, setIsSearchShown] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const labels = {
    title: intl.formatMessage(
      definedTranslation.visualSearch.resultsModal.matches,
    ),
  };

  const removeHotspotQuery = () => {
    dispatch(setQuery(''));
    dispatch(getMaterialsByHotspot({}));
  };

  const handleQuery = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(setQuery(text));
    dispatch(
      getMaterialsByHotspot({
        similarityText: text,
      }),
    );
    onCancelTextClick();
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };
  const onCancelTextClick = () => {
    setText('');
    setIsSearchShown(false);
  };
  const onClearTextClick = () => {
    setText('');
  };
  const showSearch = () => {
    setIsSearchShown(true);
  };
  return (
    <Fragment>
      {isSearchShown ? (
        <Styled.SearchInputWrapper>
          <form onSubmit={handleQuery}>
            <Styled.SearchInput
              fullWidth
              isRounded
              value={text}
              onChange={handleTextChange}
              placeholder="Search Product Matches"
              variant="filled"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon onClick={handleQuery} />,
                endAdornment: Boolean(text) ? (
                  <CrossIcon onClick={onClearTextClick} />
                ) : null,
              }}
            />
          </form>
          <Styled.CancelButton
            color="inherit"
            variant="text"
            label="cancel"
            onClick={onCancelTextClick}
          />
        </Styled.SearchInputWrapper>
      ) : (
        <Styled.MaterialsHeader variant="h5" component="div" fontWeight="bold">
          {labels.title}
          {/*
                    <Styled.TriggerSearchButton
                        onClick={showSearch}
                        dense
                        color="secondary"
                        iconName="search"
                        size="medium"
                    />
                        */}
        </Styled.MaterialsHeader>
      )}
    </Fragment>
  );
};

export default HeaderWrapper;
