import { useEffect, useRef, useState } from 'react';

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export function useUpdateEffect(effect: any, dependencies: any = []) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies);
}

/**
 * A custom hook to help you with load/error states of an api call
 */
export const useAPICallStateHandler = () => {
  const [state, setState] = useState<{
    isLoading: boolean;
    error?: null | any;
  }>({
    isLoading: false,
    error: null,
  });

  return { state, setState };
};
