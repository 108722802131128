import React, { useMemo } from 'react';
import Portal from '@mui/base/Portal';
import { MBSnackbar } from 'components/components/MBSnackbar';
import { useDispatch, useSelector } from 'react-redux';

import { AllItemsAddedToCartSnackbar } from './AllItemsAddedToCartSnackbar';

import { AllItemsAddedToCartSnackbarExceptFinishes } from './AllItemsAddedToCartSnackbarExceptFinishes';

import { snackbarInfoSelector } from '../../redux/selectors/commonSelector';
import {
  SnackbarMessageType,
  setSnackbarInfo,
} from '../../redux/sliceCreators/commonSlice';

const Snackbar = () => {
  const { isOpen, message, messagePayload, messageType, ...rest } =
    useSelector(snackbarInfoSelector);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setSnackbarInfo({ ...rest, isOpen: false }));

    setTimeout(() => {
      dispatch(setSnackbarInfo({ isOpen: false }));
    }, 2000);
  };

  const messageDependsOnType = useMemo(() => {
    switch (messageType) {
      case SnackbarMessageType.ALL_ITEMS_ADDED_TO_CART:
        return (
          <AllItemsAddedToCartSnackbar
            totalAmountOfAddedItems={messagePayload?.totalAmountOfAddedItems}
          />
        );

      case SnackbarMessageType.ALL_ITEMS_ADDED_TO_CART_EXCEPT_FINISHES:
        return (
          <AllItemsAddedToCartSnackbarExceptFinishes
            totalAmountOfAddedItems={messagePayload?.totalAmountOfAddedItems}
          />
        );

      default:
        return message;
    }
  }, [message, messageType]);

  return (
    <Portal>
      <MBSnackbar
        autoHideDuration={4000}
        id="product_catalog_message_box"
        message={messageDependsOnType}
        onClose={handleClose}
        open={isOpen}
        {...rest}
      />
    </Portal>
  );
};

export default Snackbar;
