import { dispatchSetCurrentProjectId } from '@material-bank/projects-utils';
import { updateSelectProjectTimestamp } from '@mb/lib';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getUserAttributes } from '../helpers';
import { useAPICallStateHandler } from './customHooks';
import { definedTranslation } from '../../../pages/ProductSearch/i18n';
import { endpoints } from '../config';
import { setSnackbarInfo } from '../redux/sliceCreators/commonSlice';
import { setCurrentProjectId } from '../redux/sliceCreators/projectsSlice';
import { SnackbarStatusEnum } from '../types';

// @ts-expect-error // TODO: fix types
const postCurrentUserProject = async (projectId) => {
  const userData = getUserAttributes();

  const response = await fetch(endpoints.postCurrentUserProject, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userData.signin_token}`,
    },
    body: JSON.stringify({
      project_id: projectId,
    }),
  });

  return response.json();
};

export const useSwitchCurrentUserProject = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { state, setState } = useAPICallStateHandler();

  const labels = {
    success: intl?.formatMessage(
      definedTranslation.projectMessages.projectUpdated,
    ),
    error: intl?.formatMessage(
      definedTranslation.projectMessages.errorProjectSet,
    ),
  };

  // @ts-expect-error // TODO: fix types
  const setCurrentUserProject = async (projectId, showSnackbar = true) => {
    try {
      setState({ isLoading: true });
      await postCurrentUserProject(projectId);
      dispatch(setCurrentProjectId(projectId));
      updateSelectProjectTimestamp();

      /**
       * dispatch an event to update the current project;
       * temporary solution for syncing the current project id until we implement the common projects cache
       * TODO: remove this once we have the common projects cache
       */
      dispatchSetCurrentProjectId(projectId.toString());

      if (showSnackbar) {
        dispatch(
          setSnackbarInfo({
            isOpen: true,
            message: labels.success,
            status: SnackbarStatusEnum.SUCCESS,
          }),
        );
      }
      setState({ isLoading: false });
    } catch (error) {
      console.error('cannot set current project id', error);
      dispatch(
        setSnackbarInfo({
          isOpen: true,
          message: error.message ?? labels.error,
          status: SnackbarStatusEnum.DANGER,
        }),
      );
      setState({ isLoading: false, error });
    }
  };

  return { ...state, setCurrentUserProject };
};

export default useSwitchCurrentUserProject;
