import orderBy from 'lodash/orderBy';
import { createDeepEqualSelector } from './';
import { RootState } from '../store';
export const hotspotSelector = (state: RootState): RootState['hotspot'] =>
  state.hotspot;
export const isSearchingSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.isSearching,
);
export const isSearchCompleteSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.isSearchComplete,
);
export const hotspotColorsSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => orderBy(hotspot.colors || [], ['density'], ['desc']),
);
export const hotspotSelectedColorsSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.selectedColors || [],
);
export const hotspotQuerySelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.query,
);
export const hotspotPositionSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.crop,
);
export const hotspotResultsSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.results,
);
export const hotspotLabelsSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.labels || [],
);
export const hotspotSelectedLabelsSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.selectedLabels,
);

export const xRequestIdSelector = createDeepEqualSelector(
  hotspotSelector,
  (hotspot) => hotspot.xRequestId,
);
