import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import commonSlice from './sliceCreators/commonSlice';
import hotspotSlice from './sliceCreators/hotspotSlice';
import projectsSlice from './sliceCreators/projectsSlice';

const store = configureStore({
  reducer: {
    hotspot: hotspotSlice,
    projects: projectsSlice,
    common: commonSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export default store;
