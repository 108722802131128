import React, { useState, useEffect } from 'react';
import { getLocaleData } from '@mb/lib/i18n';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { ThemeContainer } from 'ui-widgets';
import VisualSearchModal from '../../../components/VisualSearchModal/VisualSearchModal';

const VisualSearchModalWrapper = () => {
  const [langData, setLangData] = useState({
    currentLocale: 'en',
    messages: undefined,
  });

  /**
   * Function to set language form local data
   * @returns void
   */
  const handleLangData = async () => {
    const data = await getLocaleData();
    // @ts-expect-error // TODO: fix types
    setLangData(data);
  };

  useEffect(() => {
    handleLangData().catch((e) => console.log(e));
  }, []);

  return (
    <IntlProvider
      messages={langData?.messages}
      locale={langData?.currentLocale}
      defaultLocale="en"
    >
      <ThemeContainer>
        <VisualSearchModal />
      </ThemeContainer>
    </IntlProvider>
  );
};

const container = document.getElementById('visualSearchWrapper');
const root = createRoot(container!);
root.render(<VisualSearchModalWrapper />);
