import React, { useState } from 'react';
import { Box } from '@mui/system';
import MBChip from 'components/Elements/MBChip/MBChip';
import xor from 'lodash/xor';
import { ContentTextile } from './types';
import { getMaterialsByHotspot } from '../api/hotspot';
import {
  hotspotLabelsSelector,
  hotspotSelectedLabelsSelector,
} from '../redux/selectors/hotspotSelector';
import { setSelectedLabels } from '../redux/sliceCreators/hotspotSlice';
import { useAppSelector, useAppDispatch } from '../redux/store';

const numberToShow = 10;
const LabelsWrapper: React.FC<{ image: string }> = ({ image }) => {
  const [showMore, setShowMore] = useState(false);
  const labels = useAppSelector(hotspotLabelsSelector);
  const selectedLabels = useAppSelector(hotspotSelectedLabelsSelector);
  const dispatch = useAppDispatch();

  const handleLabelClick = (item: ContentTextile) => {
    const currentItems = xor(selectedLabels, [item.label]);
    dispatch(setSelectedLabels(currentItems));
    dispatch(
      getMaterialsByHotspot({
        labels: currentItems,
        imageUrl: image,
      }),
    );
  };
  const handleSetMore = () => {
    setShowMore(!showMore);
  };

  const labelsList = !showMore ? labels.slice(0, numberToShow) : labels;
  return (
    <Box display="flex" flexWrap="wrap" gap="10px" marginBottom="16px">
      {labelsList?.map((item: ContentTextile) => {
        const isSelected = selectedLabels?.indexOf(item.label) !== -1;
        return (
          <MBChip
            key={item.label}
            clickable
            fontSize="16px"
            color={isSelected ? 'primary' : 'secondary'}
            variant="filled"
            labelText={item.label}
            onClick={() => handleLabelClick(item)}
          />
        );
      })}
      {labels?.length > 5 && (
        <MBChip
          clickable
          fontSize="16px"
          color="secondary"
          variant="filled"
          labelText={showMore ? 'Less' : 'More'}
          onClick={handleSetMore}
        />
      )}
    </Box>
  );
};

export default LabelsWrapper;
