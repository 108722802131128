import { sendViewSearchResultsAnalyticsEvent } from '@mb/lib/analytics';
import { getUserLang } from '@mb/lib/i18n';
import orderBy from 'lodash/orderBy';
import {
  checkStockAvailability,
  transformMaterialToAppFormat,
} from '../helpers';
import { getUserAttributes, extractPhotoExtension } from '../helpers';
import { bridgeSettings } from '../HotspotMainWrapper/config';
import {
  hotspotPositionSelector,
  hotspotQuerySelector,
  hotspotSelectedColorsSelector,
  hotspotSelectedLabelsSelector,
} from '../redux/selectors/hotspotSelector';
import {
  setColors,
  setLabels,
  setResults,
  setIsSearching,
  setSelectedColors,
  setQuery,
  setSelectedLabels,
  setXRequestId,
} from '../redux/sliceCreators/hotspotSlice';
import { AppDispatch } from '../redux/store';
import { CropData, FilterLabels } from '../types';

interface PayloadBody {
  crop?: CropData;
  similarityText?: string;
  colors?: string[];
  imageUrl?: string;
  isNewSpot?: boolean;
  isInit?: boolean;
  labels?: string[];
}
interface ColorPayloadBody {
  crop?: CropData;
  imageUrl: string;
}

const extraFieldsList = [
  'configurations',
  'components',
  'family_id',
  'taxonomy',
  'ss_facet_color_filter',
  'light_reflectance_value',
  'sample_note',
  'is_digital',
];

export const getColorsByHotspot =
  (payload: ColorPayloadBody) => async (dispatch: AppDispatch) => {
    try {
      const body = {
        siteId: bridgeSettings.filterId,
        lang: 'en',
        crop: payload?.crop,
        image_url: payload?.imageUrl,
        extraFields: ['filters'],
      };
      const data = await fetch(bridgeSettings.filterUrl, {
        method: 'POST',
        body: JSON.stringify(body),
      }).then((res) => {
        return res.json();
      });
      dispatch(setColors(data?.colors || []));
      dispatch(setLabels(data?.filters?.['filter.content_textile'] || []));
      return data?.colors || [];
    } catch (e) {
      console.log(e);
      dispatch(setColors([]));
      dispatch(setLabels([]));
      return [];
    }
  };
export const getMaterialsByHotspot =
  // @ts-expect-error // TODO: fix types
  (payload: PayloadBody) => async (dispatch: AppDispatch, getState) => {
    try {
      const state = getState();
      const reduxCrop = hotspotPositionSelector(state);
      const reduxColors = hotspotSelectedColorsSelector(state);
      const reduxLabels = hotspotSelectedLabelsSelector(state);
      const reduxHotspotQuery = hotspotQuerySelector(state);
      const {
        similarityText = reduxHotspotQuery,
        crop = reduxCrop,
        imageUrl,
        colors = reduxColors,
        labels = reduxLabels,
        isNewSpot,
        isInit,
      } = payload;
      const userData = getUserAttributes();
      const payloadColors = colors;
      const configLang =
        typeof ENVIRONMENT_REGION !== 'undefined' ? ENVIRONMENT_REGION : 'US';
      const userLang = getUserLang();
      let searchCore = {};
      if ((similarityText || '')?.length === 0 || isNewSpot) {
        searchCore = { crop };
      } else {
        searchCore = { similarityText };
      }
      let colorCondition = {};
      if ((payloadColors || []).length > 0) {
        colorCondition = {
          'filter.virtual_color_group': payloadColors,
          'bgfilter.visibility': 'Catalog',
        };
      } else {
        colorCondition = { 'bgfilter.visibility': 'Catalog| Search' };
      }
      if (Array.isArray(labels) && labels?.length > 0 && !isNewSpot) {
        // @ts-expect-error // TODO: fix types
        searchCore['filter.content_textile'] = labels;
      }
      const body = {
        siteId: bridgeSettings.siteId,
        lang: `${userLang}-${configLang}`,
        resultsPerPage: 9,
        page: 1,
        q: '',
        fieldsOnly: false,
        extraFields: extraFieldsList,
        similarityUrl: imageUrl,
        customerEmail: userData?.email,
        customerId: Number(userData?.customer_id),
        'bgfilter.country_permission': userData?.country,
        'bgfilter.state_permission': userData?.state,
        ...searchCore,
        ...colorCondition,
      };
      if (isNewSpot) {
        dispatch(setSelectedColors([]));
        dispatch(setSelectedLabels([]));
        dispatch(setQuery(''));
      }
      if (isInit) {
        dispatch(setIsSearching(true));
      }
      const data = await fetch(bridgeSettings.siteUrl, {
        method: 'POST',
        body: JSON.stringify(body),
      }).then((res) => {
        const xRequestId = res.headers.get('x-request-id');
        if (xRequestId != null) {
          dispatch(setXRequestId(xRequestId));
        }
        return res.json();
      });
      const imageExtension = imageUrl ? extractPhotoExtension(imageUrl) : 'jpg';
      sendViewSearchResultsAnalyticsEvent({
        query: imageExtension,
        searchType: 'visual_search',
        resultsNumber: data?.pagination?.totalResults || 0,
      });
      let results = [];
      if (Array.isArray(data?.results)) {
        // @ts-expect-error // TODO: fix types
        results = data.results.map((item, index) =>
          transformMaterialToAppFormat(
            { ...item, is_hotspot_result: true },
            // @ts-expect-error // TODO: fix types
            false,
            index,
          ),
        );
      }
      dispatch(setResults(results));
      const resultsWithStockData = await checkStockAvailability(results);
      if ((resultsWithStockData || []).length > 0) {
        dispatch(setResults(resultsWithStockData));
      }

      const filtersGroup = data?.facets?.find(
        // @ts-expect-error // TODO: fix types
        (item) => item.field === FilterLabels.TAXONOMY,
      );
      const colorsGroup = data?.facets?.find(
        // @ts-expect-error // TODO: fix types
        (item) => item.field === FilterLabels.COLOR,
      );

      if (Array.isArray(filtersGroup?.values)) {
        const orderedFilters = orderBy(
          filtersGroup?.values,
          ['count'],
          ['desc'],
        );
        dispatch(setLabels(orderedFilters || []));
      }

      if (Array.isArray(colorsGroup?.values)) {
        const orderedColors = orderBy(colorsGroup?.values, ['count'], ['desc']);
        dispatch(setColors(orderedColors || []));
      }

      dispatch(setIsSearching(false));
    } catch (e) {
      console.log(e);
      dispatch(setIsSearching(false));
      dispatch(setResults([]));
    }
  };
