import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/system';
import * as Styled from './HotspotMainWrapper.styled';

const HotspotMaterialsSkeleton = () => {
  return (
    <Box width="100%">
      <Box display="flex" mb="1em" gap="8px">
        <Skeleton
          variant="rounded"
          width={150}
          height={36}
          sx={{ borderRadius: '20px' }}
        />
        <Skeleton
          variant="rounded"
          width={70}
          height={36}
          sx={{ borderRadius: '20px' }}
        />
        <Skeleton
          variant="rounded"
          width={120}
          height={36}
          sx={{ borderRadius: '20px' }}
        />
      </Box>

      <Styled.LineDivider />
      <Box display="flex" gap="20px" mb="1em">
        <Skeleton variant="circular" width={36} height={36} />
        <Skeleton variant="circular" width={36} height={36} />
        <Skeleton variant="circular" width={36} height={36} />
        <Skeleton variant="circular" width={36} height={36} />
      </Box>
      <Grid container spacing={2}>
        {Array.from({ length: 9 }).map((item, index) => (
          <Grid item xs={4} key={index}>
            <Skeleton
              variant="rounded"
              width="100%"
              height={180}
              sx={{ borderRadius: '20px' }}
            />
            <Box display="flex" justifyContent="space-between" my="1em">
              <Box display="flex" flexDirection="column" flexGrow="1" gap="8px">
                <Skeleton
                  variant="rounded"
                  width="90%"
                  height={16}
                  sx={{ borderRadius: '20px' }}
                />
                <Skeleton
                  variant="rounded"
                  width="70%"
                  height={16}
                  sx={{ borderRadius: '20px' }}
                />
              </Box>
              <Skeleton variant="circular" width={36} height={36} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const MatchesLoadingSkeleton = () => {
  return (
    <Styled.Wrapper isImagePdp fullHeight={false} reduceHeight={0} top={0}>
      <Styled.GridWrapper container spacing={0}>
        <Styled.SingleImageGrid item xs={12} md={6} lg={6}>
          <Styled.ImageContainer>
            <Skeleton
              variant="rounded"
              width="90%"
              height={500}
              sx={{ borderRadius: '20px' }}
            />
          </Styled.ImageContainer>
        </Styled.SingleImageGrid>
        <Grid className="rightGrid" item xs={12} md={6} lg={6}>
          <Styled.RightColumn withBorder>
            <Box display="flex" mb="1em">
              <Skeleton
                variant="rounded"
                width={240}
                height={36}
                sx={{ borderRadius: '20px' }}
              />
            </Box>
            <HotspotMaterialsSkeleton />
          </Styled.RightColumn>
        </Grid>
      </Styled.GridWrapper>
    </Styled.Wrapper>
  );
};

export { HotspotMaterialsSkeleton, MatchesLoadingSkeleton };
