import { endpoints } from '../../config';
import { getUserAttributes } from '../../helpers';
import {
  setProjects,
  setProjectTypes,
  setProjectPhases,
  setIsProjectsLoaded,
} from '../../redux/sliceCreators/projectsSlice';
import { NewProject } from '../../types/projects';
import { client } from '../http';

const phasesRoute =
  'rest/V1/sdg-project/projectphase/search?searchCriteria[currentPage]=1';

// @ts-expect-error // TODO: fix types
export const getProjectPhases = () => async (dispatch) => {
  try {
    const { data } = await client.get(phasesRoute);
    if (Array.isArray(data?.items)) {
      // @ts-expect-error // TODO: fix types
      const formattedPhases = data?.items.map(({ phase_name, phase_id }) => ({
        value: phase_id,
        label: phase_name,
      }));
      dispatch(setProjectPhases(formattedPhases));
    }
  } catch (error) {
    console.log('erro getTypes', error);
  }
};

// @ts-expect-error // TODO: fix types
export const getUserProjects = () => async (dispatch) => {
  try {
    const { data } = await client.get(endpoints.projects);
    if (Array.isArray(data?.items)) {
      dispatch(setProjects(data.items));
    }
    dispatch(setIsProjectsLoaded(true));
  } catch (e) {
    dispatch(setIsProjectsLoaded(true));
    console.log('Error getUserProjects:', e);
  }
};

// @ts-expect-error // TODO: fix types
export const getUserProjectTypes = () => async (dispatch) => {
  try {
    const { data } = await client.get(endpoints.getProjectTypes);
    if (Array.isArray(data)) {
      dispatch(setProjectTypes(data));
    }
    return null;
  } catch (e) {
    console.log('Error getUserProjectTypes:', e);
    return null;
  }
};

export const createProject = async (project: NewProject) => {
  try {
    const userData = getUserAttributes();
    const { data } = await client.post(
      endpoints.projects,
      { project },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData.signin_token}`,
        },
      },
    );
    return data;
  } catch (e) {
    console.log('Error createProject:', e);
    return null;
  }
};
