import React, { useMemo, useCallback, useEffect } from 'react';
import {
  AnalyticsEventName,
  CustomEventName,
  Project,
  isProjectTypeOther,
} from '@mb/lib';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './HotspotMainWrapper.styled';
import { isSearchPage } from '../../SearchBar/helpers';
import { sendAnalytics } from '../api/events';
import { getUserProjectTypes } from '../api/projects';
import { ProjectModalListener } from '../components/ProjectsModal';
import useCurrentProject from '../hooks/useCurrentProject';
import useSwitchCurrentUserProject from '../hooks/useSwitchCurrentUserProject';
import { cartItemsSelector } from '../redux/selectors/commonSelector';
import {
  setCreatedProject,
  setCurrentProjectId,
  setIsProjectModalOpen,
  setProjectModalEventLocation,
} from '../redux/sliceCreators/projectsSlice';
import { useAppSelector, useAppDispatch } from '../redux/store';
import { ResultCard } from './Card/ResultCard';
import {
  hotspotResultsSelector,
  xRequestIdSelector,
} from '../redux/selectors/hotspotSelector';
import {
  projectTypesListSelector,
  projectsListSelector,
} from '../redux/selectors/projectsSelector';
import { removeCartItemFromQueue } from '../redux/sliceCreators/commonSlice';
import { ProductSearchMaterial } from '../types/material';
import { IProject } from '../types/projects';

const activeBagTime = 2500;

const HotspotMaterials = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const hotspotResults = useAppSelector(hotspotResultsSelector);
  const projects = useAppSelector(projectsListSelector);
  const cartItems = useAppSelector(cartItemsSelector);
  const projectTypes = useAppSelector(projectTypesListSelector);
  const xRequestId = useAppSelector(xRequestIdSelector);
  const { setCurrentUserProject } = useSwitchCurrentUserProject();
  const { currentProject } = useCurrentProject();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  // If something was added to Cart, show isAdded icon for X secconds,
  // and then remove icon
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const oldCartItems = cartItems?.filter(
        // @ts-expect-error // TODO: fix types
        (item) => now - item.time >= activeBagTime,
      );
      if (oldCartItems?.length > 0) {
        dispatch(removeCartItemFromQueue(oldCartItems));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cartItems]);

  const structuredProjects = useMemo(() => {
    return [...projects].map((project) => ({
      id: project.project_id,
      label: project.project_title,
    }));
  }, [projects]);

  const cartItemsIds = useMemo(() => {
    // @ts-expect-error // TODO: fix types
    return cartItems?.map((item) => item.entityId) || [];
  }, [cartItems]);

  const currentProjectData = {
    id: currentProject?.project_id,
    label: currentProject?.project_title ?? 'Select project..',
  };

  const handleProjectCreate = useCallback(
    (params?: { analyticsEventLocation?: string }) => {
      const { analyticsEventLocation } = params ?? {};

      dispatch(setIsProjectModalOpen(true));

      if (analyticsEventLocation) {
        dispatch(setProjectModalEventLocation(analyticsEventLocation));
      }
    },
    [],
  );

  const onProjectSelect = useCallback((projectId: number) => {
    dispatch(setCurrentProjectId(projectId));
  }, []);

  const onProjectCreate = useCallback((project: IProject) => {
    dispatch(setCreatedProject(project));
  }, []);

  const handleProjectClick = (
    projectId: string,
    options?: { analyticsEventLocation?: string },
  ) => {
    const { analyticsEventLocation } = options ?? {};

    const project = projects.find(
      // @ts-expect-error // TODO: fix types
      (project) => project.project_id === projectId,
    );

    if (project) {
      const isOtherProjectType = isProjectTypeOther({
        projectTypes,
        projectType: project.project_type,
      });
      const isProjectLocationUnknown = !project.project_location;

      if (isOtherProjectType || isProjectLocationUnknown) {
        window.dispatchEvent(
          new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
            detail: {
              defaultView: 'UPDATE_PROJECT',
              currentProjectId: project.project_id,
              analyticsEventLocation,
              onCurrentProjectSave: (project: any) => {
                onProjectSelect(project?.externalId || project?.project_id);
                dispatch(getUserProjectTypes());
              },
              onProjectCreate: (project: Project) => {
                // @ts-expect-error // TODO: fix types
                onProjectCreate(project);
              },
            },
          }),
        );
      } else {
        setCurrentUserProject(project.project_id);

        mitGTM.sendEvent({
          event: AnalyticsEventName.SET_PROJECT,
          event_location: analyticsEventLocation,
          action: 'Select',
        });
      }
    }
  };

  const handleProductClick = async (item: ProductSearchMaterial) => {
    if (isSearchPage) {
      window.dispatchEvent(
        new CustomEvent('handleSuggestionClick', {
          detail: {
            id: item.entityId,
            xRequestId: xRequestId,
          },
        }),
      );
    } else {
      await sendAnalytics({ id: [String(item.entityId)], type: 'view' });
      window.location.href = item.url;
    }
  };

  return (
    <div>
      <Styled.MaterialsGridWrapper>
        {hotspotResults?.map((data: ProductSearchMaterial) => (
          <Styled.MaterialCell key={data.sku}>
            <ResultCard
              key={data?.sku}
              material={data}
              isMultiselect={false}
              isSelected={false}
              projects={structuredProjects}
              selectedProject={currentProjectData}
              cartItemsIds={cartItemsIds}
              onProductClick={handleProductClick}
              onCreateProjectClick={handleProjectCreate}
              onProjectClick={handleProjectClick}
              isMobile={isMobile}
              analyticsEventLocation="Visual Search Modal"
              onProjectCreate={onProjectCreate}
              onProjectSelect={onProjectSelect}
              intl={intl}
            />
          </Styled.MaterialCell>
        ))}
        <ProjectModalListener />
      </Styled.MaterialsGridWrapper>
    </div>
  );
};

export default HotspotMaterials;
