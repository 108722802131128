const configGlobal = typeof SEARCH_CONFIG !== 'undefined' ? SEARCH_CONFIG : {};

export const apiCatalogURL =
  configGlobal?.product?.siteUrl?.replace(/\/$/, '') ||
  'https://search.materialbank.com/v2';

export const bridgeSettings = {
  siteUrl:
    configGlobal?.inspirationBridge?.siteUrl?.replace(/\/$/, '') ||
    'https://search.materialbank.com/v2',
  siteId: configGlobal?.inspirationBridge?.siteId || 'materialbank_product',
  filterUrl:
    configGlobal?.inspirationBridge?.filterUrl?.replace(/\/$/, '') ||
    'https://search.materialbank.com/v2/inspiration/byFeature',
  filterId:
    configGlobal?.inspirationBridge?.filterId || 'materialbank_inspiration',
};
