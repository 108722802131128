import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  shouldTriggerProjectSelectionDialog,
  Project,
  CustomEventName,
  triggerContactRepModal,
} from '@mb/lib';
import { ProductCard } from 'components/Cards/ProductCard';
import { ProductCardActionType } from 'components/Cards/ProductCard/ProductCard.types';
import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';

import { definedTranslation } from '../../../../pages/ProductSearch/i18n';
import {
  triggerBoardsModal,
  getIsPaintProductType,
  triggerAssociatedProductsModal,
} from '../../../../pages/ProductSearch/utils/helpers';
import { sendAnalytics } from '../../api/events';
import {
  mapProductToAnalyticsEcommerceItem,
  buildAnalyticsParamsToPDP,
} from '../../helpers/analytics';
import useAddToBag from '../../hooks/useAddToBag';
import { setMaterialsInQueue } from '../../redux/sliceCreators/commonSlice';

interface ssColor {
  color_name: string;
  entity_id: string | number;
  exact_color: string;
  image_url: string;
  manufacturer_sku: string;
  sku: string;
}

// TODO: describe types
export interface ResultCardProps extends Record<string, any> {}

const ResultCard = React.memo<ResultCardProps>((props) => {
  const {
    material: product,
    imageSize = 252,
    selectedProject,
    onProductClick,
    onCreateProjectClick,
    onProjectClick,
    projects,
    isMultiselect,
    onMaterialSelect,
    isSelected,
    cartItemsIds = [],
    isColorway = false,
    isMiniPDPFinish = false,
    isMobile,
    isHoverAllowed,
    isOutlined,
    relatedProductInfo = null,
    onContextMenuClick,
    onAddToCart,
    shouldTriggerRecModal = true,
    itemListId = 'visual_search',
    itemListName = 'Visual Search',
    analyticsEventLocation,
    index,
    onProjectSelect,
    onProjectCreate,
    intl,
  } = props;

  const {
    name,
    categories,
    residentialCommercial,
    indoorOutdoorUse,
    priceSign,
    entityId,
    manufacturer,
    manufacturerSku,
    ssFacetColorFilter,
    blurhash,
    isInRealTimeStock,
    isNew,
    isJustAdded,
    colorwayId,
    childrenCount,
    isCollection,
    associatedFinishProducts = [],
  } = product;

  const selectedSSFacetColor = useRef<ssColor | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const isInCart = cartItemsIds.includes(entityId);
  const itemId = Number(selectedSSFacetColor?.current?.entity_id) || entityId;
  const isPaint = getIsPaintProductType(product);
  const isProduct = product?.productFinishType?.includes('Product');
  const isFinishMaterial = product?.productFinishType?.includes('Finish');
  const showContactRepButton = false;

  const { isLoading: isAddToCartLoading, addToBag } = useAddToBag();
  const dispatch = useDispatch();

  const cardLabels = {
    addToCartLabel: intl?.formatMessage(definedTranslation.card.addToCartLabel),
    addedToCartLabel: intl?.formatMessage(
      definedTranslation.card.addedToCartLabel,
    ),
    createProjectButtonLabel: intl?.formatMessage(
      definedTranslation.card.createProjectButtonLabel,
    ),
    inStockLabel: intl?.formatMessage(definedTranslation.card.inStockLabel),
    outOfStockLabel: intl?.formatMessage(
      definedTranslation.card.outOfStockLabel,
    ),
    projectPreLabel: intl?.formatMessage(
      definedTranslation.card.projectPreLabel,
    ),
    saveButtonLabel: intl?.formatMessage(
      definedTranslation.card.saveButtonLabel,
    ),
    savedButtonLabel: intl?.formatMessage(
      definedTranslation.card.savedButtonLabel,
    ),
    savingButtonLabel: intl?.formatMessage(
      definedTranslation.card.savingButtonLabel,
    ),
  };
  const internalLabels = {
    newLabel: intl?.formatMessage(definedTranslation.card.newLabel),
    justAddedLabel: intl?.formatMessage(definedTranslation.card.justAddedLabel),
    colorwaysLabel: intl?.formatMessage(definedTranslation.card.colorwaysLabel),
    orderNow: intl?.formatMessage(definedTranslation.card.orderNow),
    finishes: intl?.formatMessage(definedTranslation.card.finishes),
  };

  const sampleFinishesLabel = intl?.formatMessage(
    definedTranslation.card.sampleFinishesLabel,
  );

  useEffect(() => {
    const imageUrl = product.thumbnailImageUrl;

    // image from API comes with get param ?width=X, we need to replace that
    // to adjust size to column size
    const defaultSizeImage = imageUrl?.split('?')[0];
    const finalImageSize = Math.max(600, imageSize);
    setImageUrl(
      imageUrl ? `${defaultSizeImage}?width=${finalImageSize}` : null,
    );
  }, [product, imageSize, ssFacetColorFilter]);

  let materialUrl: string;
  const pdpTrackParams: string = buildAnalyticsParamsToPDP({
    product,
    itemListId,
    itemListName,
    index: index !== undefined ? index + 1 : undefined,
  });

  if (colorwayId != null) {
    materialUrl = `${product.url}?activeChild=${colorwayId}&${pdpTrackParams}`;
  } else if (selectedSSFacetColor?.current != null) {
    materialUrl = `${product.url}?activeChild=${selectedSSFacetColor?.current?.entity_id}&${pdpTrackParams}`;
  } else if (isFinishMaterial || !isCollection) {
    materialUrl = `${product.url}?activeChild=${entityId}&${pdpTrackParams}`;
  } else {
    materialUrl = `${product.url}?${pdpTrackParams}`;
  }

  // @ts-expect-error // TODO: fix types
  const handleFavoritesClick = async (item) => {
    const currentItemId = item?.id != null ? item.id : itemId;
    const addToWishlistEventItem = mapProductToAnalyticsEcommerceItem({
      product,
      itemListId,
      itemListName,
      index: index !== undefined ? index + 1 : undefined,
    });

    const currencies = {
      US: 'USD',
      EU: 'EUR',
      JP: 'JPY',
    };

    triggerBoardsModal({
      itemsData: [
        {
          id: currentItemId,
          type: isFinishMaterial ? 'finish' : 'material',
        },
      ],
      onSuccessAnalyticsEvents: [
        { ecommerce: null },
        {
          event: 'add_to_wishlist',
          list_id: product?.isDigital ? 'digital_sampling' : itemListId,
          list_name: product?.isDigital ? 'Digital sampling' : itemListName,
          method: 'Single',
          type: 'Materials',
          name: null,
          action: null,
          experiment_name: null,
          experiment_version: null,
          event_location: analyticsEventLocation ?? '',
          ecommerce: {
            currency: currencies[ENVIRONMENT_REGION],
            value: 0,
            items: [addToWishlistEventItem],
          },
        },
      ],
    });
    const stringIds = [String(currentItemId)];
    const value = {};
    await sendAnalytics({ id: stringIds, type: 'favorite', ...value });
  };

  const handleItemSelect = () => {
    onMaterialSelect?.({
      ...product,
      entityId: selectedSSFacetColor?.current?.entity_id || product.entityId,
    });
  };

  const handleAddToBoxClick = useCallback(
    // @ts-expect-error // TODO: fix types
    (item) => {
      if (
        associatedFinishProducts?.length > 0 &&
        isFinishMaterial &&
        !isMiniPDPFinish
      ) {
        // If Finishes - trigger modal to select associated Products
        const productsIds = associatedFinishProducts.join('|');

        if (shouldTriggerProjectSelectionDialog()) {
          window.dispatchEvent(
            new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
              detail: {
                currentProjectId: selectedProject?.id,
                analyticsEventLocation,
                onCurrentProjectSave: (project: any) => {
                  onProjectSelect?.(project?.externalId || project?.project_id);
                  triggerAssociatedProductsModal(productsIds, {
                    id: entityId,
                    name,
                    manufacturerName: manufacturer,
                    productImageUrl: imageUrl,
                  });
                },
                onProjectCreate: (project: Project) => {
                  onProjectCreate?.(project);
                },
              },
            }),
          );
          return;
        }

        triggerAssociatedProductsModal(productsIds, {
          id: entityId,
          name,
          manufacturerName: manufacturer,
          productImageUrl: imageUrl,
        });
        return;
      }

      let currentProduct = {
        ...product,
        entityId: selectedSSFacetColor?.current?.entity_id || product.entityId,
      };
      // set current entityId, if selected Variation in ListView
      if (item?.id != null) {
        currentProduct = {
          ...product,
          entityId: item.id,
          sku: item.sku,
        };
      }
      // Add Related Product Info, if miniPDP finish
      const relatedProductData = isMiniPDPFinish ? relatedProductInfo : null;

      if (projects?.length > 0 && Boolean(selectedProject.id)) {
        const gaEventLocation = 'Visual Search';

        if (shouldTriggerProjectSelectionDialog()) {
          window.dispatchEvent(
            new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
              detail: {
                currentProjectId: selectedProject?.id,
                analyticsEventLocation,
                onCurrentProjectSave: (project: any) => {
                  onProjectSelect(project?.externalId || project?.project_id);
                  onAddToCart?.();
                  addToBag(
                    [currentProduct],
                    project.project_id,
                    project,
                    relatedProductData,
                    gaEventLocation,
                    isColorway,
                    shouldTriggerRecModal,
                  );
                },
                onProjectCreate: (project: Project) => {
                  onProjectCreate?.(project);
                },
              },
            }),
          );
          return;
        }
        onAddToCart?.();
        addToBag(
          [currentProduct],
          selectedProject.id,
          undefined,
          relatedProductData,
          gaEventLocation,
          isColorway,
          shouldTriggerRecModal,
        );
      } else {
        onCreateProjectClick?.();
        dispatch(setMaterialsInQueue([currentProduct]));
      }
    },
    [product, selectedProject, projects, imageUrl, analyticsEventLocation],
  );

  const handleProductClick = (id?: string) => {
    if (isMultiselect) {
      onMaterialSelect?.(product);
    } else {
      const newUrl =
        id != null && colorwayId == null
          ? `${product.url}?activeChild=${id}`
          : materialUrl;
      onProductClick?.({
        ...product,
        url: newUrl,
        manufacturerSku:
          selectedSSFacetColor?.current?.manufacturer_sku || manufacturerSku,
        thumbnailImageUrl: imageUrl,
        entityId: id || itemId,
        isColorApplied: Boolean(
          selectedSSFacetColor?.current?.manufacturer_sku,
        ),
        index,
      });
    }
  };

  const handleRightClick = (id: string | number) => {
    onContextMenuClick?.({ ...product, entityId: id || itemId });
  };

  const handleProjectClick = useCallback(
    (id: string) => {
      onProjectClick?.(id, { analyticsEventLocation });
    },
    [projects, analyticsEventLocation, onProjectClick],
  );

  const onCreateProject = useCallback(() => {
    onCreateProjectClick?.({ analyticsEventLocation });
  }, [analyticsEventLocation, onCreateProjectClick]);

  const usageList =
    residentialCommercial?.length > 0 ? residentialCommercial : null;

  const priceLabel = isPaint ? null : priceSign;
  const availableSubcategories: string[] =
    // @ts-expect-error // TODO: fix types
    product.categories?.filter((item) => item.includes('>')) ?? [];
  const firstSubcategory = availableSubcategories?.[0]?.split('>')?.pop();
  const lastSubcategory = availableSubcategories?.[
    availableSubcategories.length - 1
  ]
    ?.split('>')
    ?.pop();
  const subcategoryToShow = product.categories?.includes(firstSubcategory)
    ? lastSubcategory
    : firstSubcategory;
  // @ts-expect-error // TODO: fix types
  const usageType = usageList?.map((usage) => usage.split(' ')[0]) || [];
  const colorwaysNumber =
    ssFacetColorFilter?.length > 1
      ? `${ssFacetColorFilter?.length} ${internalLabels.colorwaysLabel}`
      : null;
  const features = [
    priceLabel,
    subcategoryToShow,
    ...usageType,
    colorwaysNumber,
  ].filter((item) => item != null);
  const count = Number(childrenCount) > 1 ? childrenCount : undefined;
  const isNewLabel = isNew
    ? [{ label: internalLabels.newLabel }]
    : isJustAdded
    ? [{ label: internalLabels.justAddedLabel }]
    : [];
  const blurhashString = blurhash?.length >= 6 ? blurhash : null;
  const finalCardUrl = isProduct
    ? materialUrl?.split('?activeChild')?.[0]
    : materialUrl;
  const cardLabel = isColorway ? product?.title : name;
  const actionType: ProductCardActionType = isProduct
    ? 'customAction'
    : 'addToCart';
  const showProjectsDropdown = !isMobile && !isProduct;
  const finishesCount =
    product.components?.reduce(
      // @ts-expect-error // TODO: fix types
      (totalCount, finishGroup) =>
        totalCount + (finishGroup.items?.length ?? 0),
      0,
    ) ?? 0;
  const finishesCountLabel = `${finishesCount} ${internalLabels.finishes}`;
  const taxonomyLabel = product.taxonomy?.[0]?.split('>')?.pop();
  const subtitle1 = isProduct
    ? finishesCountLabel
    : subcategoryToShow || taxonomyLabel;
  const contactRepModalData = {
    event_location: analyticsEventLocation,
    product_id: entityId,
    product_name: name,
    project_id: selectedProject?.id,
    manufacturer_sku: manufacturerSku,
    manufacturer: manufacturer,
  };

  return (
    <ProductCard
      title={manufacturer}
      label={cardLabel}
      subtitle1={subtitle1}
      subtitle2={cardLabel}
      entityId={entityId}
      imageUrl={imageUrl!}
      projects={projects}
      productUrl={finalCardUrl}
      features={features}
      topInfoItems={isNewLabel}
      isOvernight={isInRealTimeStock}
      isMobileDevice={isMobile}
      showAdvancedInfo
      showSelectOnHover={false}
      showSelect={isMultiselect}
      isHoverAllowed={isHoverAllowed}
      isOutlined={isOutlined}
      isSelected={isSelected}
      showHoverFeatures
      showBag
      childrenNumber={count}
      showProjectsDropdown={showProjectsDropdown}
      isAddedToFavorites={false}
      afterLabel={undefined}
      blurhash={blurhashString}
      showContainedInfo
      showExtraInfo={false}
      actionType={actionType}
      actionButtonText={sampleFinishesLabel}
      // @ts-expect-error // TODO: fix types
      onClickAddToFavorites={isMobile ? null : handleFavoritesClick}
      selectedProject={selectedProject}
      onAddToBag={handleAddToBoxClick}
      isAddToCartLoading={isAddToCartLoading}
      isAddedToCart={isInCart}
      onProductClick={handleProductClick}
      onActionButtonClick={handleProductClick}
      // @ts-expect-error // TODO: fix types
      onProjectClick={handleProjectClick}
      onSelected={handleItemSelect}
      onCreateProject={onCreateProject}
      // @ts-expect-error // TODO: fix types
      onRightClick={handleRightClick}
      showContactRepButton={showContactRepButton}
      triggerContactRepModal={triggerContactRepModal}
      contactRepModalData={contactRepModalData}
      {...cardLabels}
    />
  );
}, isEqual);

ResultCard.displayName = 'ResultCard';

export { ResultCard };
