import { createDeepEqualSelector } from './';

export const commonSelector = (state: any) => state.common;

export const snackbarInfoSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.snackbarInfo,
);

export const cartItemsSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.cartItems || [],
);

export const materialsInQueueSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.materialsInQueue || [],
);

export const errorMessageSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.errorMessage,
);
