import get from 'lodash/get';
import { checkMultipleMaterialsAvailability } from '../api/materials';
import { ProductSearchMaterial } from '../types/material';

// @ts-expect-error // TODO: fix types
export const transformMaterialToAppFormat = (result) => {
  return {
    productFinishType: result.product_finish_type,
    paintProductType: result.paint_product_type,
    isNew: result.is_new,
    isJustAdded: result.just_added,
    isCustom: false,
    isCollection: result.is_collection,
    isInStock: result.in_stock === '1',
    id: result.id,
    childrenCount: result.children_count,
    californiaProp65: result.california_prop_65,
    isInRealTimeStock: result.isInRealTimeStock,
    entityId: result.entity_id,
    ssStatePermission: result.ss_state_permission,
    manufacturer: result.manufacturer,
    manufacturerSku: result.manufacturer_sku ?? [],
    productType: result.product_type,
    qty: result.qty,
    residentialCommercial: result.residential_commercial,
    imageUrl: result.imageUrl,
    name: get(result, 'name', ''),
    sku: result.sku,
    brand: get(result, 'brand_name[0]', ''),
    url: result.url,
    sibling: null,
    siblings: result.siblings,
    categories: result.categories,
    ssFacetColorFilter: result.ss_facet_color_filter,
    indoorOutdoorUse: result.indoor_outdoor_use,
    priceSign: result.price_sign,
    color: result.color,
    thumbnailImageUrl: result.thumbnailImageUrl,
    itemType: result.item_type,
    configurations: result.configurations,
    blurhash: result.blurhash,
    components: result.components,
    colorwayId: result._colorway_id,
    ssItemType: result.ss_item_type,
    associatedFinishProducts: result.associated_finish_products,
    familyId: result.family_id,
    taxonomy: result.taxonomy || [],
    lightReflectanceValue: result.light_reflectance_value,
    originalMaterial: result,
    isPrimary: result.is_primary,
    sampleNote: result.sample_note,
    attachments: result.attachments,
  };
};

export const checkStockAvailability = async (
  results: ProductSearchMaterial[] = [],
) => {
  try {
    const entityIds = results.map((result) => result.entityId);
    const materialsStatus = await checkMultipleMaterialsAvailability(entityIds);

    if (materialsStatus == null) {
      return results;
    }

    // @ts-expect-error // TODO: fix types
    const updatedResponses = results.map((product) => {
      if (Array.isArray(materialsStatus)) {
        for (const response of materialsStatus) {
          if (String(response.product_id) === String(product.entityId)) {
            return {
              ...product,
              isInRealTimeStock: Number(response.qty) > 0,
            };
          }
        }
      }
      if (product.isInRealTimeStock == null) {
        return {
          ...product,
          isInRealTimeStock: false,
        };
      }
    });
    return updatedResponses;
  } catch (e) {
    console.log(e);
    return results;
  }
};

const INFO_USER_LOCALSTORAGE = 'mage-cache-storage';

export const getUserAttributes = () => {
  return (
    JSON.parse(localStorage.getItem(INFO_USER_LOCALSTORAGE) || '{}')
      ?.customer || {}
  );
};

// @ts-expect-error // TODO: fix types
export async function fetchWithTimeout(resource, options = {}) {
  // @ts-expect-error // TODO: fix types
  const { timeout = 1000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

// @ts-expect-error // TODO: fix types
export function getIsPaintProductType(material) {
  if (material.paintProductType != null || material.ssItemType != null) {
    const paintType = material.paintProductType?.toLowerCase() || '';
    const ssItemType = material.ssItemType?.toLowerCase() || '';
    return (
      paintType.includes('product') ||
      paintType.includes('paint') ||
      ssItemType.includes('paint')
    );
  } else {
    return false;
  }
}

export const goToLogin = () =>
  (window.location.href =
    '/customer/account/login/' +
    `?callbackUrl=${encodeURIComponent(
      window.location.pathname + window.location.hash,
    )}`);

export function extractPhotoExtension(url: string) {
  // Extract the pathname from the URL
  const urlObject = new URL(url);
  const pathname = urlObject.pathname;

  // Get the file extension
  const extension = pathname.split('.').pop();

  return extension;
}
